import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import notify from "../../../shared/notify";

const AddModal = ({show , addTicket , onHideModal , ticketType ,handleDeparmentName }) => {
    
  const handleSubmit =() =>{
    addTicket()
  }

  return (
    <Modal show={show} centered className="sucess-content" onHide={() => onHideModal()} >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Ticket Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomInput
          label={"Ticket Type"}
          value={ticketType}
          onChange={(e) => {handleDeparmentName(e.target.value)}}
          placeholder={"Enter Ticket Type"}
        />

        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 16 }}
            onClick={() => handleSubmit()}
            children={"Submit"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
