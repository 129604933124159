import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import CustomInput from '../../components/CustomInput';
import Loader from '../../Helper/Loader';
import AddPlantName from '../../components/Modal/PlantName/addPlantName';
import EditPlantName from '../../components/Modal/PlantName/editPlantName';

const PlantName = () => {
    const { mainStore } = useStores()
    const [name, setName] = useState("")
    const [plantNameList, setPlantNameList] = useState([])
    const [totalPage, setTotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData);
    const [addPlantNameModal, setAddPlantNameModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [plantName, setPlantName] = useState("")
    const [editPlantName, setEditPlantName] = useState("")
    const [showEditModal, setShowEditModal] = useState(false)
    const [item, setItem] = useState()
    const [status, setStatus] = useState();
    const [entityList, setEntityList] = useState([]);
    const [entity, setEntity] = useState("")
    const [entityName, setEntityName] = useState("")
    const [entityCode, setEntityCode] = useState("")
    const [plantOfLocation, setPlantOfLocation] = useState("")
    const [editPlantOfLocation, setEditPlantOfLocation] = useState("")




    useEffect(() => {
        fetchPlantName()
    }, [name, entityCode])

    useEffect(() => {
        fetchEntitiesList();

    }, [])

    let options = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ];


    const fetchEntitiesList = async () => {
        await mainStore.fetchEntitiesList((status, response) => {
            if (response?.meta?.status === 'success') {
                let data = response?.data?.entitites;
                let entities = [];
                for (let i = 0; i < data.length; i++) {
                    entities.push({ label: data[i].name, value: data[i].entity_code })
                }
                setEntityList(entities);
            } else {
                setEntityList([])
            }
        }, (error) => {
            setEntityList([])
            notify(error?.response?.meta?.message, "danger");
        })
    }



    const fetchPlantName = async (page) => {
        let payload = {
            pageNumber: page ? page : 1,
            limit: 10,
            filters: {},
        };
        if (name) {
            payload.filters.plant_name = name;
        }

        if (entityCode) {
            payload.filters.entity_name = entityCode
        }
        setIsLoading(true)
        await mainStore.getAllPlantName(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    setPlantNameList(response?.data?.data?.filteredData?.results)
                    setTotalPage(response?.data?.data?.totalPages);
                    setIsLoading(false)
                } else {
                    setPlantNameList([]);
                    setTotalPage(null);
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
            (error) => {
                setPlantNameList([]);
                setTotalPage(null);
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)
            }
        );
    };

    const handleEdit = (item) => {
        console.log(item);
        setEntityName(item?.company_id)
        setEditPlantName(item?.plant_name)
        setStatus(item?.isActive)
        setShowEditModal(true)
        setItem(item)
        setEditPlantOfLocation(item?.plantLocation?.location)
    }
   

    const handlePageChange = (pageNumber) => {
        fetchPlantName(pageNumber);
        setCurrentPage(pageNumber);
    }




    const addPlantName = async () => {
        let payload = { plant_name: plantName, company_id: entity, location: plantOfLocation }
        setIsLoading(true)
        await mainStore.addPlant(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddPlantNameModal(false)
                setPlantName("")
                setEntity("")
                fetchPlantName()
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const UpdatePlantName = async () => {
        let payload = {
            plant_name: editPlantName,
            isActive: status,
            company_id: entityName,
            location: editPlantOfLocation
        }
        setIsLoading(true)
        await mainStore.editPlantName(item?.plant_id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                fetchPlantName()
                setIsLoading(false)
                setShowEditModal(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })

    }


    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3 login-text">
                        <CustomCard
                            cardtitle={"Plant "}
                            cardboldtitle={"Name List"}
                            search={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label={"Search Plant Name"}
                                            placeholder={"Search Plant Name"}
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label={"Search Entity Name"}
                                            placeholder={"Search Entity Name"}
                                            value={entityCode}
                                            onChange={(e) => {
                                                setEntityCode(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            toprightview={
                                <CustomButton
                                    onClick={() => {
                                        setAddPlantNameModal(true)
                                    }}
                                    children={"Add Plant Name"}
                                />
                            }
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div class="table-responsive listing-table">
                                        <table class="table ">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Entity Name</th>
                                                    <th scope="col">Entity Code</th>
                                                    <th scope="col">Plant Name</th>
                                                    <th scope="col">Plant Location</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {plantNameList?.length > 0 &&
                                                    plantNameList.map((item, index) => {
                                                        return (
                                                            <tr key={Math.random()}>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.entities?.name}</td>
                                                                <td>{item?.company_id}</td>
                                                                <td>{item?.plant_name}</td>
                                                                <td>{item?.plantLocation?.location}</td>
                                                                <td>
                                                                    {item?.isActive ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        onClick={() => handleEdit(item)}
                                                                        width={20}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={require("../../assets/images/edit.png")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                        {plantNameList?.length == 0 && (
                                            <p className="page-not-found" style={{ height: 200 }}>
                                                No Records Found
                                            </p>
                                        )}
                                    </div>
                                    {plantNameList?.length > 0 && (
                                        <div className="pagination-container">
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPage}
                                                onPageChange={(e) => {
                                                    handlePageChange(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                    <AddPlantName
                        show={addPlantNameModal}
                        onHide={() =>
                            setAddPlantNameModal(false)
                        }
                        onHideModal={() => {
                            setAddPlantNameModal(false);
                            fetchPlantName();
                        }}
                        plantName={plantName}
                        handleDesignationName={(e) => setPlantName(e)}
                        addPlantName={addPlantName}
                        entityList={entityList}
                        entity={entity}
                        handleSetEntity={(e) => setEntity(e)}
                        plantOfLocation={plantOfLocation}
                        handleplantLocation={(e) => setPlantOfLocation(e)}
                    />
                    <EditPlantName
                        show={showEditModal}
                        onHide={() =>
                            setShowEditModal(false)
                        }
                        onHideModal={() => {
                            setShowEditModal(false);
                            fetchPlantName();
                        }}
                        editPlantName={editPlantName}
                        handlePlantName={(e) => setEditPlantName(e)}
                        UpdatePlantName={UpdatePlantName}
                        options={options}
                        status={status}
                        handleChangeStatus={(e) => setStatus(e)}
                        entityList={entityList}
                        entityName={entityName}
                        handleSetEntityName={(e) => setEntityName(e)}
                        handleEditLocation={(e) => setEditPlantOfLocation(e)}
                        editPlantOfLocation={editPlantOfLocation}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default PlantName