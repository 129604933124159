import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import CustomCard from '../../components/CustomCard';
import ApproverRoleCard from '../../components/ApproverRoleCard';
import { NavItem } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";


const Master = () => {
    const navigate = useNavigate()
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData)



    const handleBusiness = () => {
        navigate("/business")
    }

    const handleDesignation = () => {
        navigate("/designation")
    }

    const handleDeparment = () => {
        navigate("/department")
    }

    const handleEntity = () => {
        navigate("/entity")
    }

    const handleBankDetals = () => {
        navigate("/bankDetails")
    }

    const handlePlantName = () => {
        navigate("/plantName")
    }

    const handlePosition = () => {
        navigate("/position")
    }

    // const handlePlantLocation =() =>{
    //     navigate("/plantLocation")

    // }

    const handleCategory = () =>{
        navigate("/category")
    }
    const handleSubCategory = () =>{
        navigate("/sub-category")
    }
    const handleticketType = () =>{
        navigate("/view-ticket-type")
    }

    return (
        <React.Fragment>
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3">
                        <CustomCard
                            cardtitle={"Master"}
                            cardboldtitle={"List"}
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div className="row">
                                        <div className='col-md-3' onClick={handleEntity}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow1.png")}
                                                role={"Entity"}

                                            />
                                        </div>
                                        <div className='col-md-3' onClick={handleBankDetals}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow2.png")}
                                                role={"Bank Details"}

                                            />
                                        </div>
                                        <div className='col-md-3' onClick={handleDesignation}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow3.png")}
                                                role={"Designation"}

                                            />
                                        </div>
                                        <div className='col-md-3' onClick={handleDeparment}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow1.png")}
                                                role={"Department"}

                                            />
                                        </div>
                                        <div className='col-md-3' onClick={handleBusiness}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow2.png")}
                                                role={"Business"}

                                            />
                                        </div>
                                        <div className='col-md-3' onClick={handlePlantName}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow3.png")}
                                                role={"Plant Name"}

                                            />
                                        </div>
                                        <div className='col-md-3' onClick={handlePosition}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow1.png")}
                                                role={"Position"}

                                            />
                                        </div>
                                        {/* <div className='col-md-3' onClick={handlePlantLocation}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow1.png")}
                                                role={"Plant Location"}
                                               
                                            />
                                        </div> */}
                                        <div className='col-md-3' onClick={handleCategory}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow2.png")}
                                                role={"Category"}

                                            />
                                        </div>
                                        <div className='col-md-3' onClick={handleSubCategory}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow3.png")}
                                                role={"Sub Category"}

                                            />
                                        </div>
                                        {/* <div className='col-md-3' onClick={handleticketType}>
                                            <ApproverRoleCard
                                                src={require("../../assets/images/arrow1.png")}
                                                role={"Ticket Type"}

                                            />
                                        </div> */}
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Master