import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";
import notify from "../../../shared/notify";

const AddPlantName = ({ show, addPlantName, onHideModal, plantName, handleDesignationName, entityList, entity, handleSetEntity,plantOfLocation ,handleplantLocation}) => {

    const handleSubmit = () => {
        addPlantName()
    }

    return (
        <Modal show={show} centered className="sucess-content" onHide={() => onHideModal()} >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add Plant Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                <Customselect
                    label="Entity"
                    options={entityList}
                    value={entity}
                    onChange={(e) => handleSetEntity(e.target.value)}
                />
                </div>
                <div className="col-md-6">
                <CustomInput
                    label={"Plant Name"}
                    value={plantName}
                    onChange={(e) => { handleDesignationName(e.target.value) }}
                    placeholder={"Enter Plant Name"}
                />
                </div>
                    <div className="col-md-6">
                <CustomInput
                    label={"Plant Location"}
                    value={plantOfLocation}
                    onChange={(e) => { handleplantLocation(e.target.value) }}
                    placeholder={"Enter Plant Location"}
                />
        </div>

                <div className="text-center mt-3">
                    <CustomButton
                        styles={{ width: 120, height: 38, fontSize: 16 }}
                        onClick={() => handleSubmit()}
                        children={"Submit"}
                    />
                </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddPlantName;
