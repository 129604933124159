import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Login from "../pages/auth/login";
import Dashboard from "../pages/Dashboard";
import Entity from "../pages/Entity";
import BankDetails from "../pages/BankDetails"
import Designation from "../pages/Designation"
import Department from "../pages/Department"
import Business from "../pages/Business";
import Master from "../pages/Master";
import PlantName from "../pages/PlantName";
import Position from "../pages/position";
// import PlantLocation from "../pages/PlantLocation";
import Category from "../pages/Category";
import SubCategory from "../pages/SubCategory";
import Ticket from "../pages/Ticket";
import ViewAll from "../pages/Ticket/viewAll";
import ViewTickets from "../pages/Ticket/ViewTicketById";
import TicketType from "../pages/TicketType";

const AppRoutes = () => {
  let token = sessionStorage.getItem("token");

  if (token) {
    return (
      <Router>
        <Sidebar />
        <Routes>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/`} element={<Dashboard />} />
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/entity`} element={<Entity />}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/bankDetails`} element={<BankDetails />}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/designation`} element={<Designation />}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/department`} element={<Department />}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/business`} element={<Business />}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/master`} element={<Master />}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/plantName`} element={<PlantName/>}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/position`} element={<Position/>}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/category`} element={<Category/>}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/sub-category`} element={<SubCategory/>}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ticket`} element={<Ticket/>}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/view-all-ticket`} element={<ViewAll/>}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/view-ticket`} element={<ViewTickets/>}/>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/view-ticket-type`} element={<TicketType/>}/>
          {/* <Route path={`${process.env.REACT_APP_BASE_FOLDER}/plantLocation`} element={<PlantLocation/>}/> */}
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route path={`${process.env.REACT_APP_BASE_FOLDER}/`} element={<Login />} />
        </Routes>
      </Router>
    );
  }
};

export default AppRoutes;
