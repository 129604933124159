import React from "react";
import "./customInput.scss";

const CustomInput = (props) => {
  const {
    label,
    type = "text",
    name,
    value,
    onChange,
    placeholder,
    style,
    readOnly,
    onBlur,
    onKeyDown
  } = props;
  return (
    <div className="custominput-container">
      <div className={type=="date" ? "custom-input login-text" :"custom-input "}   >
        <label>
          {label}
          {props.require && <span className="text-danger">*</span>}
        </label>
        <input
          className={style}
          readOnly={readOnly}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          style={{ backgroundColor: readOnly ? '#e3e3e3' : 'white' }}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
};

export default CustomInput;
