import React from "react";
import "./sidebarStyle.scss";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();

  let url = location.pathname.split("/").pop();

  return (
    <div className="sidebar-container">
      <div className="sidebar-view">
        <div className="wrapper">
          <div className="siderbar-logo">
            <img src={require("../../assets/images/logo.png")} />
          </div>
          <nav id="sidebar">
            <Link
              className="decoration-none"
              to={`${process.env.REACT_APP_BASE_FOLDER}/`}
            >
              <div
                className={
                  url == "" ? "custom-nav-item active" : "custom-nav-item"
                }
              >
                <img
                  className="nav-icon"
                  src={require("../../assets/images/users.png")}
                />
                <span className="custom-nav-link">Users</span>
              </div>
            </Link>
            {/* <Link
              className="decoration-none"
              to={`${process.env.REACT_APP_BASE_FOLDER}/ticket`}
            >
              <div
                className={
                  url == "ticket" || url =="view-all-ticket" || url =="view-ticket" ? "custom-nav-item active" : "custom-nav-item"
                }
              >
                <img
                  className="nav-icon"
                  src={require("../../assets/images/users.png")}
                />
                <span className="custom-nav-link">Ticket</span>
              </div>
            </Link> */}
            <Link
              className="decoration-none"
              to={`${process.env.REACT_APP_BASE_FOLDER}/master`}
            >
              <div
                className={
                  url == "master" || url == "category" || url == "position" ||url == "sub-category" ? "custom-nav-item active" : "custom-nav-item"
                }
              >
                <img
                  className="nav-icon"
                  src={require("../../assets/images/add-user.png")}
                />
                <span className="custom-nav-link">Master</span>
              </div>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
