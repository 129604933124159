import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import CustomInput from '../../components/CustomInput';
import Loader from '../../Helper/Loader';
import AddModal from '../../components/Modal/TicketType/addModal';
import EditModal from "../../components/Modal/TicketType/editModal"

const TicketType = () => {
    const { mainStore } = useStores()
    const [name, setName] = useState("")
    const [ticketTypeList, setTicketTypeList] = useState([])
    const [totalPage, setTotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData);
    const [addModal, setAddModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [ticketType, setTicketType] = useState("")
    const [editTicketType, setEditTicketType] = useState("")
    const [showEditModal, setShowEditModal] = useState(false)
    const [item, setItem] = useState()
    const [status, setStatus] = useState();



    useEffect(() => {
        fetchTicketTypeList()
    }, [name])

    let options = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ];



    const fetchTicketTypeList = async (page) => {
        let payload = {
            pageNumber: page ? page : 1,
            limit: 10,
            filters: {},
        };
        if (name) {
            payload.filters.name = name;
        }
        setIsLoading(true)
        await mainStore.getAllTicketType(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    setTicketTypeList(response?.data?.data?.filteredData?.results)
                    setTotalPage(response?.data?.data?.totalPages);
                    setIsLoading(false)
                } else {
                    setTicketTypeList([]);
                    setTotalPage(null);
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
            (error) => {
                setTicketTypeList([]);
                setTotalPage(null);
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)
            }
        );
    };

    const handleEdit = (item) => {
        setEditTicketType(item?.name)
        setStatus(item?.isActive)
        setShowEditModal(true)
        setItem(item)
    }

    const handleChangeStatus = (e) => {
        setStatus(e)
    }

    const handlePageChange = (pageNumber) => {
        fetchTicketTypeList(pageNumber);
        setCurrentPage(pageNumber);
    }


   


    const addTicket = async () => {
        let payload = {
            name: ticketType
        }
        setIsLoading(true)
        await mainStore.addTicket(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddModal(false)
                setTicketType("")
                fetchTicketTypeList()
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const handleEditTicket = async () => {
        let payload = {
            name: editTicketType,
            isActive: status
        }
        setIsLoading(true)
        await mainStore.editTicket(item?.id, payload, (res) => {
            if (res?.data?.status === "success") {
                notify(res?.data?.message)
                fetchTicketTypeList()
                setIsLoading(false)
                setShowEditModal(false)
            } else {
                notify(res?.data?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.message, "danger")
            setIsLoading(false)
        })

    }


    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3 login-text">
                        <CustomCard
                            cardtitle={"Ticket "}
                            cardboldtitle={"Type"}
                            searchName={
                                <CustomInput
                                    label={"Search Ticket Type"}
                                    placeholder={"Search Ticket Type"}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            }
                            toprightview={
                                <CustomButton
                                    onClick={() => {
                                        setAddModal(true)
                                    }}
                                    children={"Add Ticket Type"}
                                />
                            }
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div class="table-responsive listing-table">
                                        <table class="table ">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Ticket Type</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ticketTypeList?.length > 0 &&
                                                    ticketTypeList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.name}</td>
                                                                <td>
                                                                    {item?.isActive ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        onClick={() => handleEdit(item)}
                                                                        width={20}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={require("../../assets/images/edit.png")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                        {ticketTypeList?.length == 0 && (
                                            <p className="page-not-found" style={{ height: 200 }}>
                                                No Records Found
                                            </p>
                                        )}
                                    </div>
                                    {ticketTypeList?.length > 0 && (
                                        <div className="pagination-container">
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPage}
                                                onPageChange={(e) => {
                                                    handlePageChange(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                    <AddModal
                        show={addModal}
                        onHide={() =>
                            setAddModal(false)
                        }
                        onHideModal={() => {
                            setAddModal(false);
                            fetchTicketTypeList();
                        }}
                        ticketType={ticketType}
                        handleDeparmentName={(e) => setTicketType(e)}
                        addTicket={addTicket}
                    />
                    <EditModal
                        show={showEditModal}
                        onHide={() =>
                            setShowEditModal(false)
                        }
                        onHideModal={() => {
                            setShowEditModal(false);
                            fetchTicketTypeList();
                        }}
                        editTicketType={editTicketType}
                        handleEditName={(e) =>  setEditTicketType(e)}
                        handleEditTicket={handleEditTicket}
                        options={options}
                        status={status}
                        handleChangeStatus={handleChangeStatus}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default TicketType