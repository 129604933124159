import { makeAutoObservable } from "mobx";
import Service from "../shared/service/service";
import service from "../shared/service/service";


class mainStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loginAPI(formData, callback, callbackerror) {
        await Service.post("login", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getUsers(formData, callback, callbackerror) {
        await Service.post("users", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async blockUser(userId, formData, callback, callbackerror) {
        await Service.post(`block_user/${userId}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async addUsers(formData, callback, callbackerror) {
        await Service.post("signup", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getRoles(formData, callback, callbackerror) {
        await Service.get(`roles`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editUser(id, formData, callback, callbackerror) {
        await Service.post(`users/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getUserData(id, formData, callback, callbackerror) {
        await Service.get(`users/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getEntity(formData, callback, callbackerror) {
        await Service.post("all_entities", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async addEntities(formData, callback, callbackerror) {
        await Service.post("entities", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getEntityData(id, formData, callback, callbackerror) {
        await Service.get(`entities/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editEntity(id, formData, callback, callbackerror) {
        await Service.post(`entities/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async uploadUsersExcel(formData, callback, callbackerror) {
        await Service.postFile("uploadUsersExcel", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async fetchBankDetails(formData, callback, callbackerror) {
        await Service.post("fetchBankDetails", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async fetchEntitiesList(callback, callbackerror) {
        await Service.get("entities", async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async addBank(formData, callback, callbackerror) {
        await Service.post("addBankDetails", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getBankData(id, formData, callback, callbackerror) {
        await Service.get(`bankData/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editBankData(id, formData, callback, callbackerror) {
        await Service.post(`updateBankDetails/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getAllDepartment(callback , callbackerror){
        await Service.get("departments" ,async (status, response) =>{
            return callback(status , response)
        }).catch((error) =>{
            callbackerror(error)
        })
    }
    async getAllDesignation(callback , callbackerror){
        await Service.get("designations" ,async (status, response) =>{
            return callback(status , response)
        }).catch((error) =>{
            callbackerror(error)
        })
    }

    async getAllFilterDepartment(formData, callback, callbackerror) {
        await Service.post("all_departments", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async addDepartment(formData, callback, callbackerror) {
        await Service.post("departments", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async editDepartment(id, formData, callback, callbackerror) {
        await Service.post(`departments/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllFilterDesignation(formData, callback, callbackerror) {
        await Service.post("all_designations", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async addDesignation(formData, callback, callbackerror) {
        await Service.post("designations", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async editDesignation(id, formData, callback, callbackerror) {
        await Service.post(`designations/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllFilterBusiness(formData, callback, callbackerror) {
        await Service.post("all_businesses", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async addBusiness(formData, callback, callbackerror) {
        await Service.post("businesses", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async editBusiness(id, formData, callback, callbackerror) {
        await Service.post(`businesses/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllPlantName(formData, callback, callbackerror) {
        await Service.post("all_company_plants", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async addPlant(formData, callback, callbackerror) {
        await Service.post("company_plants", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editPlantName(id, formData, callback, callbackerror) {
        await Service.post(`company_plants/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllPlantNamewithoutFilter(callback , callbackerror){
        await Service.get("company_plants" ,async (status, response) =>{
            return callback(status , response)
        }).catch((error) =>{
            callbackerror(error)
        })
    }
    


    // async getAllPlantLocation(formData, callback, callbackerror) {
    //     await Service.post("all_company_plants_location", formData, async (response) => {
    //         return callback(response)
    //     }).catch((error) => {
    //         return callbackerror(error);
    //     })
    // }


    // async addLocation(formData, callback, callbackerror) {
    //     await Service.post("company_plants_location", formData, async (response) => {
    //         return callback(response)
    //     }).catch((error) => {
    //         return callbackerror(error);
    //     })
    // }

    // async editPlantLocation(id, formData, callback, callbackerror) {
    //     await Service.post(`company_plants_location/${id}`, formData, async (response) => {
    //         return callback(response)
    //     }).catch((error) => {
    //         return callbackerror(error);
    //     })
    // }

    async fetchBusiness (callback , callbackerror){
       await Service.get("businesses", async(status , res) =>{
           return callback (status , res)
       }).catch((error) =>{
        return callbackerror(error)
       })
    }

    async getPosition(formData, callback, callbackerror) {
        await Service.post("getOrgPosition", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async addPosition(formData, callback, callbackerror) {
        await Service.post("createOrgPosition", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editPosition(id, formData, callback, callbackerror) {
        await Service.post(`updateOrgJobPosition/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

     async getAllCategoryWithoutFilter(callback , callbackerror){
        await Service.get("categories" ,async (status, response) =>{
            return callback(status , response)
        }).catch((error) =>{
            callbackerror(error)
        })
    }

    async getSubCategoryWithoutFilter(formData, callback, callbackerror) {
        await Service.post("filter_multiple_subcategories", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllReportingManager(callback , callbackerror){
        await Service.get("cms/allReportingManager" ,async (status, response) =>{
            return callback(status , response)
        }).catch((error) =>{
            callbackerror(error)
        })
    }
    async addCategory(formData ,callback , callbackerror){
        await Service.post("categories" ,formData ,async (status, response) =>{
            return callback(status , response)
        }).catch((error) =>{
            callbackerror(error)
        })
    }

    async getcategory(formData, callback, callbackerror) {
        await Service.post("all_categories", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editCategory(id, formData, callback, callbackerror) {
        await Service.post(`categories/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getSubcategory(formData, callback, callbackerror) {
        await Service.post("all_subcategories", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async addSubCategory(formData ,callback , callbackerror){
        await Service.post("subcategories" ,formData ,async (status, response) =>{
            return callback(status , response)
        }).catch((error) =>{
            callbackerror(error)
        })
    }

    async editSubCategory(id, formData, callback, callbackerror) {
        await Service.post(`subcategories/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllCmsComplaints(formData, callback, callbackerror) {
        await Service.post(`cms/complaint/all`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllCategories(formData, callback, callbackerror) {
        await Service.get(`categories`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getPriorityNoFilters(formData, callback, callbackerror) {
        await Service.get(`getPriorityNoFilters`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async auditTrailCms(id, formData ,callback, callbackerror) {
        await Service.getUser(`cms/complaint/audit/trail/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getDashboardCount( formData ,callback, callbackerror) {
        await Service.get(`cms/complaints/all/status/count`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getStatus(formData, callback, callbackerror) {
        await Service.get(`complaint_status`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAllTicketType(formData, callback, callbackerror) {
        await Service.post("getComplaintIssueType", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }


    async addTicket(formData, callback, callbackerror) {
        await Service.post("createComplaintIssueType", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editTicket(id, formData, callback, callbackerror) {
        await Service.post(`updateComplaintIssueType/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    

}


export default mainStore;
