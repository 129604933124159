import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import CustomInput from '../../components/CustomInput';
import Loader from '../../Helper/Loader';
import AddCategory from '../../components/Modal/Category/addcategory';
import EditCategory from '../../components/Modal/Category/editCategory';

const Category = () => {
    const { mainStore } = useStores()
    const [name, setName] = useState("")
    const [CategoryList, setCategoryList] = useState([])
    const [totalPage, setTotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData);
    const [addModal, setAddModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [category, setCategory] = useState("")
    const [editcategoryName, setEditCategoryName] = useState("")
    const [showEditModal, setShowEditModal] = useState(false)
    const [item, setItem] = useState()
    const [status, setStatus] = useState();



    useEffect(() => {
        fetchcategory()
    }, [name])

    let options = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ];



    const fetchcategory = async (page) => {
        let payload = {
            pageNumber: page ? page : 1,
            limit: 10,
            filters: {},
        };
        if (name) {
            payload.filters.category_name = name;
        }
        setIsLoading(true)
        await mainStore.getcategory(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    setCategoryList(response?.data?.data?.filteredData?.results)
                    setTotalPage(response?.data?.data?.totalPages);
                    setIsLoading(false)
                } else {
                    setCategoryList([]);
                    setTotalPage(null);
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
            (error) => {
                setCategoryList([]);
                setTotalPage(null);
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)
            }
        );
    };

    const handleEdit = (item) => {
        console.log(item,"editttttt");
        setEditCategoryName(item?.category_name)
        setStatus(item?.is_active)
        setShowEditModal(true)
        setItem(item)
    }

   

    const handlePageChange = (pageNumber) => {
        fetchcategory(pageNumber);
        setCurrentPage(pageNumber);
    }

 


    const Addcategory = async () => {
        let payload = {
            category_name: category
        }
        setIsLoading(true)
        await mainStore.addCategory(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddModal(false)
                setCategory("")
                fetchcategory()
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const editCategory = async () => {
        let payload = {
            category_name: editcategoryName,
            is_active: status
        }
        setIsLoading(true)
        await mainStore.editCategory(item?.category_id ,payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                fetchcategory()
                setIsLoading(false)
                setShowEditModal(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        },(error) =>{
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })

    }
    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3">
                        <CustomCard
                            cardtitle={"Category"}
                            cardboldtitle={"List"}
                            searchName={
                                <CustomInput
                                    label={"Search Category Name"}
                                    placeholder={"Search Category Name"}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            }
                            toprightview={
                                <CustomButton
                                    onClick={() => {
                                        setAddModal(true)
                                    }}
                                    children={"Add Category"}
                                />
                            }
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div class="table-responsive listing-table">
                                        <table class="table ">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CategoryList?.length > 0 &&
                                                    CategoryList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.category_name}</td>
                                                                <td>{item?.is_active === "true" ? "Active" : "Inactive"}</td>
                                                                <td>
                                                                    <img
                                                                        onClick={() => handleEdit(item)}
                                                                        width={20}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={require("../../assets/images/edit.png")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                        {CategoryList?.length == 0 && (
                                            <p className="page-not-found" style={{ height: 200 }}>
                                                No Records Found
                                            </p>
                                        )}
                                    </div>
                                    {CategoryList?.length > 0 && (
                                        <div className="pagination-container">
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPage}
                                                onPageChange={(e) => {
                                                    handlePageChange(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                    <AddCategory
                        show={addModal}
                        onHide={() =>
                            setAddModal(false)
                        }
                        onHideModal={() => {
                            setAddModal(false);
                            fetchcategory();
                        }}
                        category={category}
                        handlecategoryName={(e)=>setCategory(e)}
                        Addcategory={Addcategory}
                    />
                    <EditCategory
                        show={showEditModal}
                        onHide={() =>
                            setShowEditModal(false)
                        }
                        onHideModal={() => {
                            setShowEditModal(false);
                            fetchcategory();
                        }}
                        editcategoryName={editcategoryName}
                        handleEditcategoryName={(e)=> setEditCategoryName(e)}
                        editCategory={editCategory}
                        options={options}
                        status={status}
                        handleChangeStatus={(e) =>setStatus(e)}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Category