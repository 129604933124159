import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import CustomInput from '../../components/CustomInput';
import Loader from '../../Helper/Loader';
import AddPositionModal from '../../components/Modal/Position/AddPosition';
import EditPosition from '../../components/Modal/Position/EditPosition';

const Position = () => {
    const { mainStore } = useStores()
    const [name, setName] = useState("")
    const [positionList, setPositionList] = useState([])
    const [totalPage, setTotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData);
    const [addpotionModal, setAddPotionModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [position, setPosition] = useState("")
    const [editPositionName, setEditPositionName] = useState("")
    const [showEditModal, setShowEditModal] = useState(false)
    const [item, setItem] = useState()
    const [status, setStatus] = useState();



    useEffect(() => {
        fetchPosition()
    }, [name])

    let options = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ];



    const fetchPosition = async (page) => {
        let payload = {
            pageNumber: page ? page : 1,
            limit: 10,
            filters: {},
        };
        if (name) {
            payload.filters.position_name = name;
        }
        setIsLoading(true)
        await mainStore.getPosition(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    setPositionList(response?.data?.data?.filteredData?.results)
                    setTotalPage(response?.data?.data?.totalPages);
                    setIsLoading(false)
                } else {
                    setPositionList([]);
                    setTotalPage(null);
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
            (error) => {
                setPositionList([]);
                setTotalPage(null);
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)
            }
        );
    };

    const handleEdit = (item) => {
        console.log(item);
        setEditPositionName(item?.position_name)
        setStatus(item?.is_active)
        setShowEditModal(true)
        setItem(item)
    }

   

    const handlePageChange = (pageNumber) => {
        fetchPosition(pageNumber);
        setCurrentPage(pageNumber);
    }

 


    const AddPosition = async () => {
        let payload = {
            position_name: position
        }
        setIsLoading(true)
        await mainStore.addPosition(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddPotionModal(false)
                setPosition("")
                fetchPosition()
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const editPosition = async () => {
        let payload = {
            position_name: editPositionName,
            is_active: status
        }
        setIsLoading(true)
        await mainStore.editPosition(item?.position_id ,payload, (res) => {
            if (res?.data?.status === "success") {
                notify(res?.data?.message)
                fetchPosition()
                setIsLoading(false)
                setShowEditModal(false)
            } else {
                notify(res?.data?.message, "danger")
                setIsLoading(false)
            }
        },(error) =>{
            notify(error?.response?.data?.error, "danger")
            setIsLoading(false)
        })

    }


    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3 login-text">
                        <CustomCard
                            cardtitle={"Position"}
                            cardboldtitle={"List"}
                            searchName={
                                <CustomInput
                                    label={"Search Position Name"}
                                    placeholder={"Search Position Name"}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            }
                            toprightview={
                                <CustomButton
                                    onClick={() => {
                                        setAddPotionModal(true)
                                    }}
                                    children={"Add Position"}
                                />
                            }
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div class="table-responsive listing-table">
                                        <table class="table ">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {positionList?.length > 0 &&
                                                    positionList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.position_name}</td>
                                                                <td>
                                                                    {item?.is_active ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        onClick={() => handleEdit(item)}
                                                                        width={20}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={require("../../assets/images/edit.png")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                        {positionList?.length == 0 && (
                                            <p className="page-not-found" style={{ height: 200 }}>
                                                No Records Found
                                            </p>
                                        )}
                                    </div>
                                    {positionList?.length > 0 && (
                                        <div className="pagination-container">
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPage}
                                                onPageChange={(e) => {
                                                    handlePageChange(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                    <AddPositionModal
                        show={addpotionModal}
                        onHide={() =>
                            setAddPotionModal(false)
                        }
                        onHideModal={() => {
                            setAddPotionModal(false);
                            fetchPosition();
                        }}
                        position={position}
                        handleDesignationName={(e)=>setPosition(e)}
                        AddPosition={AddPosition}
                    />
                    <EditPosition
                        show={showEditModal}
                        onHide={() =>
                            setShowEditModal(false)
                        }
                        onHideModal={() => {
                            setShowEditModal(false);
                            fetchPosition();
                        }}
                        editPositionName={editPositionName}
                        handleEditPositionName={(e)=> setEditPositionName(e)}
                        editPosition={editPosition}
                        options={options}
                        status={status}
                        handleChangeStatus={(e) =>setStatus(e)}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Position