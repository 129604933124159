import React from "react";
import "./style.scss";

const ApproverRoleCard = (props) => {
  return (
    <div className="approver-role-card-container">
      <div onClick={props.onClick} className="role-card" for="lc">
        <div className="rolecard-leftside">
          <img src={props.src} />
          <div className="label-view">
            <h3>{props.role}</h3>
            {/* <p>{props.requests} Requests</p> */}
          </div>
        </div>
        <div className="rolecard-rightside">
          <img width={"8px"} src={require("../../assets/images/right-arrow.png")} />
        </div>
      </div>
    </div>
  );
};

export default ApproverRoleCard;
