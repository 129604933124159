import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import AddEntityModal from "../../components/Modal/Entiry/addEntityModal";
import EditEntityModal from "../../components/Modal/Entiry/editEntityModal";
import CustomInput from "../../components/CustomInput";
import Loader from "../../Helper/Loader";

const Entity = (props) => {
  const { mainStore } = useStores();
  const [entityList, setEntityList] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [entityData, setEntityData] = useState("");
  const [name, setName] = useState("");
  const [totalPage, setTotalPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  let loginUserData = sessionStorage.getItem("userData");
  let parseuserData = JSON.parse(loginUserData);
  const[isLoading , setIsLoading] = useState(false)

  useEffect(() => {
    getEntityData();
  }, []);

  useEffect(() => {
    getEntityData();
  }, [name]);

  const getEntityData = async (page) => {
    let payload = {
      pageNumber: page ? page : 1,
      limit: 10,
      filters: {},
    };
    if (name) {
      payload.filters.name = name;
    }
    setIsLoading(true)
    await mainStore.getEntity(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          let entityList = response?.data?.data?.filteredData?.results;
          setEntityList(entityList);
          setTotalPage(response?.data?.data?.totalPages);
          setIsLoading(false)
        } else {
          setEntityList([]);
          setTotalPage(null);
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)

        }
      },
      (error) => {
        setEntityList([]);
        setTotalPage(null);
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)

      }
    );
  };

  const fetchEntityData = async (id) => {
    await mainStore.getEntityData(
      id,
      (status, response) => {
        if (response?.meta?.status === "success") {
          let entityData = response?.data?.entity;
          setEntityData(entityData);
          setShowEditUserModal(true);
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  const handlePageChange = (pageNumber) => {
    getEntityData(pageNumber);
    setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="dashboard-container">
        <div className="right-view">
          <Header title={`Welcome ${parseuserData?.name},`} />
          <div className="mt-3 login-text">
            <CustomCard
              cardtitle={"Entity"}
              cardboldtitle={"List"}
              searchName={
                <CustomInput
                  label={"Search Name"}
                  placeholder={"Search Name"}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              }
              toprightview={
                <CustomButton
                  onClick={() => {
                    setShowAddUserModal(true);
                  }}
                  children={"Add Entity"}
                />
              }
              cardbody={
                <div className="container-fluid px-0">
                  <div class="table-responsive listing-table">
                    <table class="table ">
                      <thead class="table-customlight">
                        <tr>
                          <th scope="col">S.NO</th>
                          <th scope="col">Name</th>
                          <th scope="col">Entity Code</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entityList?.length > 0 &&
                          entityList.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.entity_code}</td>
                                <td>
                                  {item?.isActive ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <img
                                    onClick={(e) => {
                                      e.preventDefault();
                                      fetchEntityData(item?.id);
                                    }}
                                    width={20}
                                    style={{ cursor: "pointer" }}
                                    src={require("../../assets/images/edit.png")}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {entityList?.length == 0 && (
                      <p className="page-not-found" style={{ height: 200 }}>
                        No Records Found
                      </p>
                    )}
                  </div>
                  {entityList?.length > 0 && (
                    <div className="pagination-container">
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPage}
                        onPageChange={(e) => {
                          handlePageChange(e);
                        }}
                      />
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </div>
        <AddEntityModal
          show={showAddUserModal}
          onHide={() => {
            setShowAddUserModal(false);
          }}
          onHideModal={() => {
            setShowAddUserModal(false);
            getEntityData();
          }}
        />
        <EditEntityModal
          show={showEditUserModal}
          onHide={() => {
            setShowEditUserModal(false);
          }}
          onHideModal={() => {
            setShowEditUserModal(false);
            getEntityData();
          }}
          entityData={entityData}
          key={entityData?.id}
        />
      </div>
    </React.Fragment>
  );
};

export default Entity;
